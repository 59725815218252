section.timesheet-calendar-wrap{
  width: 100%;
  padding:10px;

  overflow: scroll;
  position:relative;
}


div.timesheet-calendar{
  display: flex;
  flex-direction: column;
  width: auto;
  overflow-x: scroll;
  flex:1 1 auto;
}
div.calendar-items{
  display: flex;
  flex-direction: column;
}
div.timesheet-calendar div.calendar-day{
  border-bottom: 1px solid var(--color-border-2);
  padding: 10px;
  font-size: .8em;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
}
div.timesheet-calendar div.calendar-day div.accommdation,
div.timesheet-calendar div.calendar-day div.day{
  padding: 0 10px;
}

div.timesheet-calendar div.calendar-day div.day{

}

section.timesheet-calendar-wrap div.accommdation{
  flex:0 0 100px;
  justify-content: center;
  align-items: center;
}
