.pannel-list-item{
    display: flex;
    flex-direction:row;
    border-bottom: 1px solid var(--color-border);
    padding: 5px 5px;
    font-size: .8em;
    justify-content: flex-start;
    align-items: center;
}
.pannel-list-item:last-child{
    border-bottom: none;
}
.pannel-list-item div{
    margin-right: 10px;
    text-align: left;
}