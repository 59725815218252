 div.alert-wrap{
  position: fixed;
  top:0;
  left: 0;
  z-index: 1001;
}

.alert-container{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  z-index: 502;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:transparent;
}

.alert-container div.alert-box{
  width: 450px;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 504;
  padding: 10px;
  font-size: .9em;
}

.alert-container div.alert-box div.message-box{
  padding: 10px 0;
  border-bottom:  1px solid var(--color-border);
}

.alert-container div.alert-box div.btn-box{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.alert-message{
  margin-top: 10px;
  font-size: .8em;
  text-align: center;
}

.check-box{
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
