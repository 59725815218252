div.sidebox section{
  border-bottom: 1px solid #273242;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: .9em;
}

div.sidebox section.accommodation_menu{
  font-size: .7em;
  color:#fff;

}

.accommodation_list_item{
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding:8px 10px;;
}
