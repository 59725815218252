h1.header-accommdation{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: auto;
}

div.accommdation-menu{
  font-size: .8em;
  padding: 10px 20px;
  border-right:  1px solid var(--color-border);
  background-color: #f7f7f7;
    border-bottom: 1px solid var(--color-border);
    display: flex;
    flex-direction: row;
    align-items: center;
}
div.accommdation-menu-item{
  margin-right: 20px;
  color:#333;
}
div.accommdation-menu-item.selected{
  color:var(--color-link)
}
