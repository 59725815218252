
.css-2b097c-container{
  width: 100% !important;
}
.dryjhT{
  border-right:none !important;
  padding-right: 0 !important;
}
:root {
  --styled-select-multi-value-wrapper__padding: 3px 0 3px 5px;
  --styled-select-multi-value__background-color: #fff;
  --styled-select-multi-value__border:none;
  --styled-select-multi-value__border--hover: none;
  --styled-select-multi-value__box-shadow: none;
  --styled-select-multi-value__font-size: 0.9em;
  --styled-select-multi-value__line-height: 1.4;
  --styled-select-multi-value__margin: 2px 5px 2px 0;

}
