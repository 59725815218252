.payment-item{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}
.payment-item-row{
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  width: 100%;
  margin: 2px auto;
}
.payment-item-row div{

}
.payment-item-row.payment-id{
  font-size: .9em;
}

.payment-item-row-wrap{
  display: flex;
  flex-direction: column;
  width: 100%;
}
