.receive-mail{
    margin: 20px  ;
    padding: 20px;
    border: 1px solid var(--color-border) ;
}
.receive-mail div.title{
    font-weight: bold;
}
.receive-mail div.title,
.receive-mail div.from{
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--color-border) ;
}
.receive-mail div.from{
    font-size: .8em;
}
.receive-mail div.body{
    margin-top: 20px;
    line-height: 1.4em;
    font-size: .8em;
}