 div.overlay-wrap{
  position: fixed;
  top:0;
  left: 0;
  z-index: 1001;
  background-color: #000;
  opacity:.92;
}

div.overlay-container{
  width: 100vw;
  height: 100vh;
  
  position: fixed;
  top:0;
  left: 0;
  z-index: 1004;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:transparent;
}

div.overlay-container div.content-box{
  width: 850px;
  height: 90vh;
  overflow: scroll;
  background-color: #fff;
  border-radius: 3px;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  
}

div.overlay-container div.content-box label{
  font-weight: bold;
  width: 100%;
  color:#000;
  margin: 10px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
  position: relative;
  display: block;
  font-size: .8em;
}