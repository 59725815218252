main.login{
  width: 100%;
  height: 100vh;
  background:linear-gradient(to top right, rgba(255, 11, 94, 0.9) 10%, rgba(255, 115, 129, 0.9) 65%, rgba(245, 240, 227, 0.9) 125%);
}

div.login-pannel{
  width: 550px;
  padding-top: 50px;
  margin: 0 auto 0 auto;
}

div.login-pannel div.logo{
  width: 220px;
  margin: 0 auto 50px auto;

}

div.login-pannel section.white-box{
  background-color: #fff;
    width: 550px;
    padding: 25px;
    margin: 0;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
}
