
/********************
parts
*********************/
.acc_icon{
  display: block;
  height: 26px;
  line-height: 26px;
  min-width: 26px;
  width: 26px;
  border-radius: 50%;
  margin-right: .5em;
  text-align: center;
  font-size: 10px;
  display: flex;
  align-items: center;
  line-height: 0;
  justify-content: center;
  padding-top: 3px;
  color:#fff;
}

.acc_c1{
  background-color: #E76F51;
}
.acc_c2{
  background-color: #2A9D8F;
}
.acc_c3{
  background-color: #3DA35D;
}
.acc_c4{
  background-color: #264653;
}
.acc_c5{
  background-color: #00A8E8;
}
.acc_c6{
  background-color: #523F38;
}
.acc_c7{
  background-color: #6D6875;
}
.acc_c8{
  background-color: #003459;
}
.acc_c9{
  background-color: #4C5454;
}
.acc_c10{
  background-color: #B5838D;
}
