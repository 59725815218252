
div.overlay-wrap-left{
  position: fixed;
  top:0;
  left:0;
  width: 50%;
  min-width: calc(100vw - 600px);
  height: 100vh;
    z-index: 1001;
}
div.overlay-left{
  background-color: #fff;
  position: fixed;
  left:0;
  top:0;
  width: 100%;
  height: 100vh;
  opacity: 1;
}

div.overlay-container-left{
  width: 100%;
  position: relative;
  z-index: 502;
  background-color:transparent;
}


div.overlay-container{
  width: 100%;
  height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  z-index: 502;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:transparent;
}

div.overlay-form{
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 504;
  padding: 20px;
  font-size: .9em;
}


div.overlay-form-title{
  adding: 10px 0;
  border-bottom:  1px solid var(--color-border-2);
}

div.overlay-form-list{
    margin-top: 10px;
    display: flex;
    flex-direction:column;
}

div.overlay-form-list-item{
  margin-top: 5px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  align-items: center;
}


div.overlay-form-list-item label{
  font-size: .8em;
  color: #aaa;
  margin-left: 3px;
}

div.overlay-form-list-item div.item-value {
  width: 100%;
  box-sizing: border-box;
}
div.overlay-form-list-item div.item-value input,
div.overlay-form-list-item div.item-value textarea{
  width: 100%;
  box-sizing: border-box;
}

div.overlay-form div.btn-box{
  width: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}
div.overlay-form div.btn-box div{
  margin: 0 5px;
}

div.overlay-form div.overlay-form-2c{
  display: flex;
  flex-direction: row;
  width: 100%;
}

div.overlay-form div.overlay-form-2c div.overlay-form-2c-wide{
  width: 70%;
  padding-right: 10px;
}
div.overlay-form div.overlay-form-2c div.overlay-form-2c-narrow{
  width: 30%;
  padding-left: 10px;
  border-left: 1px solid #ddd;
}
