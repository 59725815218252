.customer-list-item,
.item-list-item,
.mail-list-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 36px;
  padding: 4px 0;
  box-sizing: border-box;
  border-bottom:  1px solid  var(--color-border);
  font-size: .8em;
}
.customer-list-item div{
  margin-left:10px;
}
.mail-list-item,
.item-list-item{
  border-right: 1px solid var(--color-border);
}
.customer-list-item.header,
.mail-list-item.header,
.item-list-item.header{
  border-top:  1px solid  var(--color-border);
  border-bottom:  1px solid  var(--color-border);
  border-bottom: none;
  padding: 10px 0;
  height: auto;
  background-color: var(--color-header);
}
.customer-list-item div.name,
.mail-list-item div.auto{
  flex: 0 1 auto;
  min-width: 1px;
  overflow: hidden;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  }

.customer-list-item div.date{
    width:120px;
    text-align: center;
}

.item-list-item div.right{
  justify-content: flex-end;
}
.item-list-item div.free{
  flex:1 1 auto;
}

.item-list-item div.w80{
    flex: 0 0 80px;
}
.item-list-item div.w100{
    flex: 0 0 100px;
}
.item-list-item div.w120{
  flex: 0 0 120px;
}
.item-list-item div.w130{
  flex: 0 0 130px;
}
.item-list-item div.w150{
  flex: 0 0 150px;
}
.item-list-item div.w180{
  flex: 0 0 180px;
}

.item-list-item div.w200{
  flex: 0 0 200px;
}
.item-list-item div.w250{
  flex: 0 0 250px;
}


.customer-list-item div.mail{
  font-size:.8em;
  width:140px;
  text-align: left;
}

.customer-list-item div.check{
  width:20px;
  font-size: 1em;
  cursor: pointer;
}

.customer-list-item div.check.able{
  color: var(--color-selected);
}
.customer-list-item div.check.disabled{
  color: #ddd;
}
.mail-list-item:hover{
  background-color: rgb(226, 248, 175);
}
.mail-list-item a{
  color: inherit;
}
.mail-list-item a:hover{
  text-decoration: underline;
}

.mail-list-item div,
.item-list-item div{
    padding:0 10px;
    box-sizing: border-box;
  border-left:1px solid var(--color-border);
}

.mail-list-item.header,
.item-list-item.header{
  border-top:  1px solid  var(--color-border);
  border-bottom: none;
  padding: 10p 0x;
  height: auto;
  font-size: .7em;
  text-align: center;
  color:#444 !important;
}
.mail-list-item div.name{
  flex: 1 1 auto;
  min-width: 1px;
  overflow: hidden;
  -webkit-box-flex: 1;
  }
  .mail-list-item div.free{
    flex: 1 1 auto;
    min-width: 1px;
    overflow: hidden;
    -webkit-box-flex: 1;
    }
.mail-list-item div.date{
    width:125px;
    flex: 0 1 125px;
    font-size: .9em;
    text-align: center;
}
.mail-list-item div.d50{
    flex: 0 1 50px;
}
.mail-list-item div.d100{
    flex: 0 1 100px;
}
.mail-list-item div.d150{
    flex: 0 1 150px;
}
.mail-list-item div.d300{
    flex: 0 1 300px;
}
.mail-list-item div.d400{
    flex: 0 1 400px;
}
.mail-list-item div.d500{
    flex: 0 1 500px;
}
.mail-list-item div.delete-f{
    flex: 0 1 100px;
    text-align: center;
    cursor: pointer;
    font-size: .9em;
    color:var(--color-selected);
}
.mail-list-item div.delete-h{
  flex: 0 1 100px;
  text-align: center;
}
.mail-list-item div.link{
  font-size: .9em;
  text-align: center;
  cursor: pointer;
  color:var(--color-selected);
}
.mail-list-item div.date.with-week{
  width:220px;
  flex: 0 1 220px;
}
.mail-list-item div.number{
  text-align: right;
  flex: 0 1 100px;
  box-sizing: border-box;
}
.mail-list-item div.accommodation,
.item-list-item div.accommodation{
  display: flex;
  justify-content: center;
  align-items: center;
}
.mail-list-item div.acc_icon,
.item-list-item div.acc_icon{
  padding-left: 10px;
  border-left:0;
}
.mail-list-item div.number.large{
  flex: 0 1 120px;
}
.mail-list-item div.right{
  text-align: right;
}
.mail-list-item div.button,
.item-list-item div.button{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 80px;
  opacity: 0;
  cursor: pointer;
}
.mail-list-item.header div.button,
.item-list-item.header div.button{
  opacity: 1;
  flex: 0 1 80px;
}
.mail-list-item:hover div.button,
.item-list-item:hover div.button{
  opacity: .8;
}

.mail-list-item.header:hover div.button,
.item-list-item.header:hover div.button{
  opacity: 1
}

.mail-list-item div.check{
  width:20px;
  font-size: 1em;
  cursor: pointer;
  flex: 0 0 20px;
}
.mail-list-item div.cancel{
  color:var(--color-selected);
  cursor:pointer;
}
section.wide div.wide-hidden{
  display: none;
}

.acc-list div{
  cursor: pointer;
}
.item-list-item.deleted{
  background-color: #f1f1f1;
  opacity: .3;
}

/***** DELETED ITEM *****/
section.right.deleted-item{
  background-color: #f1f1f1;
}
.deleted-item-title{
  width: 100%;
  text-align: center;
  margin: 20px;
  font-size: 1em;
  color:var(--color-dengerous);
}
