div.today-status{
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid var(--color-border);
  border-right: 1px solid var(--color-border);

}
div.today-status div.book-status{
  flex:1;
  padding: 0 10px 10px 10px;
  border-left: 1px solid var(--color-border);

}
div.today-status div.book-status div.books-status-item:last-child{

}

div.today-status div.books-status-item{
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  align-items: center;
  font-size: .9em;
  cursor: pointer;
}
div.today-status div.title{
  font-size: .8em;
  margin-bottom: 15px;
}
