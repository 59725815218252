div.setting-form-item{
  display: flex;
  flex-direction: column;
}

div.setting-form-item label{
  font-size: .8em;
  color:#aaa;
  margin-left: 3px;
}


div.checkbox{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.check-parts {
    padding-left: 25px;
    position: relative;
    display: flex;
    align-items: center;
    height: 20px;
    padding-top: 3px;
}
.check-parts::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    margin-top: 2px;
    border: 1px solid #ccc;
    border-radius: 0;
    transition: all .2s;
}
.form-check:checked+.check-parts::before {
    border-color: var(--color-selected);
    background: var(--color-selected);
    transition: all .2s;
}
.form-check:checked+.check-parts::after {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 5px;
    width: 5px;
    height: 10px;
    transform: rotate(40deg);
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transition: all .2s;
}

div.radiobox-wrap{
  display: flex;
  flex-direction: row;
  border:none;
}
div.radiobox-wrap.column{
  flex-direction: column;
}
div.radio{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
div.input-box label.radio-parts{
  display: block;
  position: relative;
  margin-right: 1.5em;
  padding: 1em 1em 1em 2.3em;
  -webkit-transition: .2s ease;
  transition: .2s ease;
    color:#777;
    font-size: .8em;
    cursor: pointer;
    margin: 0;
}
div.input-box label.radio-parts::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: .5em;
}
div.input-box label.radio-parts::before{
  display: block;
       width: 12px;
       height: 12px;
      margin: auto;
      border: 2px solid #92a1a7;
      border-radius: 8px;
      background: #fff;
      content: "";
}
div.input-box label.radio-parts::after{
  display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    margin: auto;
    border-radius: 3px;
    background: transparent;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
}



input:checked + label.radio-parts::before {
    border-color: var(--color-selected)
}
input:checked + label.radio-parts::after {
    background: var(--color-selected)
}


select.default {
  width: 100%;
  border: 1px solid #eee;
  color:#222;
  padding: 8px;
} 