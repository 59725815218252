div.main-content.calendar{

}

div.current-month{
  font-size: .8em;
  font-weight: bold;
}

div.calendar-monthly{
  display: flex;
  flex-direction: column;
}
div.calendar-monthly div.week{
  display: flex;
  flex-direction: row;
  background-color: #333;
}
div.calendar-monthly div.week div.date{
  width: calc(100% / 7);
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  height: 120px;
  padding: 10px;
  font-size: .8em;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  cursor: pointer;
}

div.calendar-monthly div.week div.date.has-book,
div.calendar-days div.day.has-book div.date{
  background-color: #b0eacd;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;

}
div.calendar-monthly div.week div.date.full,
div.calendar-days div.day.full div.date{
  background-color: #21bf73;
  color:#fff;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}
div.calendar-monthly div.week div.date.over,
div.calendar-days div.day.over div.date{
  background-color: #fd5e53;
  color:#fff;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}
div.calendar-monthly div.calendar-date{
  margin-bottom: 2px;
}
div.calendar-monthly div.calendar-date:hover{
  text-decoration:underline;
}

div.calendar-monthly div.week div.date.today,
div.calendar-days div.day.today{
  border:3px solid var(--color-link)
}

div.calendar-monthly div.week div.date.unselect{
  opacity: .3;
}
div.calendar-monthly div.week div.date div.book{
  font-size: .88em;
  margin-top: 2px;

  cursor: pointer;
}
div.calendar-monthly div.week div.date div.book:hover{
  text-decoration: underline;
}
div.mail-list-item.header.week div.date{
  width: calc(100% / 7);
  flex:none;
}
div.mail-list-item.deleted {
  background-color: #ddd;
}
div.calendar-monthly div.week div.date:first-child{
    border-left: 1px solid #ccc;
}

div.bottom-button-wrap{
  position: absolute;
  bottom: 10px;
  width: 100%;

  justify-content: center;
  display: none;
}
div.bottom-button-wrap.active{
  display: flex;
}

div.bottom-button-wrap div.bottom-button{
  width: 100px;
  padding: 10px 0;
  border-radius: 19px;
  background-color: var(--color-link);
  color:#fff;
  text-align: center;
  cursor: pointer;
}
div.bottom-button-wrap div.bottom-button:hover{
  opacity: .8;
}


/*** ROW  **/
div.calendar-days{
  display: flex;
  flex-direction: column;
}
div.calendar-days div.day{
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ccc;
  padding: 7px;
  font-size: .8em;
}
div.calendar-days div.day div.date{
  flex: 0 1 50px;
  cursor: pointer;
  padding: 5px;
  text-align: center;
  margin-right: 5px;
}
div.calendar-days div.day div.books{
  flex: 1 1 auto;
  cursor: pointer;
  width: calc(100% - 50px);
}
div.calendar-days div.day div.books div.book{
  display: flex;
  flex-direction: row;

  border-bottom: 1px dotted #ddd;
  padding: 5px;
}
div.calendar-days div.day div.books div.book:hover{
  background-color: #eee;
}
div.calendar-days div.day div.books div.book:last-child{
  border-bottom: none;
  margin-bottom: 0;
}
div.calendar-days div.day div.books div.book div{
  margin: 0 5px;
}
